<template>
  <div :key="componentKey">
    <div v-if="!viewAll" class="text-center">
      Select Year: <select v-model="useYear" class="btn btn-outline-secondary">
        <option v-for="year in years" :key="'year' + year">{{ year }}</option>
      </select>
    </div>
    <div v-else>
      For Year: {{ useYear }}
    </div>
    <player-averages
      :regionId="regionId"
      :aClass="aClass"
      :divisionId="divisionId"
      :players="players"
      :events="events"></player-averages>
  </div>
</template>

<script>
import axios from 'axios'
import PlayerAverages from '@/components/PlayerAverages'

export default {
  name: 'averages-view',
  components: { PlayerAverages },
  props: ['regionId', 'aClass', 'divisionId', 'year'],
  data () {
    return {
      componentKey: 0,
      events: [],
      players: [],
      years: [],
      useYear: this.year
    }
  },
  mounted () {
    const year = new Date().getFullYear()
    this.years.splice(0)
    for (let i = 0; i < 2; i++) {
      this.years.push(year - i)
    }
    if (!this.year || this.years.indexOf(parseInt(this.year)) === -1) {
      let useYear = this.years[0]
      if (sessionStorage.useYear && this.years.includes(parseInt(sessionStorage.useYear))) {
        useYear = parseInt(sessionStorage.useYear)
      }
      let p
      if (this.aClass === undefined) {
        p = this.$router.push({ path: `/report/averagesForYear/${this.regionId}/${useYear}` })
      } else {
        p = this.$router.push({ path: `/report/averagesForYear/${this.aClass}/${this.divisionId}/${useYear}` })
      }
      p.then(this.getPlayerAverages)
      return
    }
    this.useYear = this.year
    this.getPlayerAverages()
  },
  computed: {
    viewAll () { return this.aClass !== undefined }
  },
  watch: {
    regionId () {
      this.getPlayerAverages()
    },
    useYear (val) {
      this.componentKey = Math.random()
      if (parseInt(val) !== parseInt(this.year) && this.years.includes(parseInt(val))) {
        sessionStorage.useYear = val
        const p = this.aClass === undefined
          ? this.$router.push({ path: `/report/averagesForYear/${this.regionId}/${val}` })
          : this.$router.push({ path: `/report/averagesForYear/${this.aClass}/${this.divisionId}/${val}` })
        p.then(this.getPlayerAverages)
      }
    }
  },
  methods: {
    getPlayerAverages () {
      if (!this.year) { return }
      this.useYear = this.year
      axios.post('/api/report.php', {
        action: 'getPlayerAverages',
        regionId: this.regionId,
        aClass: this.aClass,
        divisionId: this.divisionId,
        year: this.year
      })
        .then(response => {
          if (response.success) {
            this.events.splice(0)
            this.events.push(...response.events)
            this.players.splice(0)
            this.players.push(...response.players)
          }
        })
    }
  }
}
</script>

<style>

</style>
