import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView'
import LoginView from '../views/LoginView'
// import AdminView from '../views/AdminView'
import ReportView from '../views/ReportView'
import AveragesView from '../views/AveragesView'

import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    meta: {
      accessLevel: 0
    },
    component: HomeView
  },
  {
    path: '/',
    redirect: { name: 'home' }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      accessLevel: 0
    },
    component: LoginView
  },
  {
    path: '/report/averages/:regionId',
    name: 'averages',
    props: true,
    meta: {
      accessLevel: 0
    },
    component: AveragesView
  },
  {
    path: '/report/averages/:aClass/:divisionId',
    name: 'averages2',
    props: true,
    meta: {
      accessLevel: 0
    },
    component: AveragesView
  },
  {
    path: '/report/averagesForYear/:regionId/:year',
    name: 'averagesForYear',
    props: true,
    meta: {
      accessLevel: 0
    },
    component: AveragesView
  },
  {
    path: '/report/averagesForYear/:aClass/:divisionId/:year',
    name: 'averagesForYear2',
    props: true,
    meta: {
      accessLevel: 0
    },
    component: AveragesView
  },
  /*
  {
    path: '/report/:eventId/:view/:columns',
    name: 'report2',
    props: true,
    component: ReportView
  },
  */
  {
    path: '/report/:eventId/:view',
    name: 'report',
    props: true,
    meta: {
      accessLevel: 0
    },
    component: ReportView
  },
  {
    path: '/report/:eventId',
    name: 'report',
    props: true,
    meta: {
      accessLevel: 0
    },
    component: ReportView
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      accessLevel: 0
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/team',
    name: 'team',
    meta: {
      accessLevel: 3
    },
    component: () => import(/* webpackChunkName: "team" */ '../views/TeamView.vue')
  },
  {
    path: '/scoring',
    name: 'scoring',
    meta: {
      accessLevel: 5
    },
    component: () => import(/* webpackChunkName: "scoring" */ '../views/ScoringView.vue')
  },
  {
    path: '/admin',
    meta: {
      accessLevel: 10
    },
    redirect: '/admin/matches'
  },
  {
    path: '/admin/:show',
    name: 'admin',
    meta: {
      accessLevel: 10
    },
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView.vue')
  },
  {
    path: '/info/:eventId',
    name: 'info',
    meta: {
      accessLevel: 0
    },
    props: true,
    component: () => import(/* webpackChunkName: "info" */ '../views/EventInfoView.vue')
  }
]

const hasAccessToRoute = (user, route) => {
  return !route.meta.accessLevel || (user && user.accessLevel >= route.meta.accessLevel)
}

const router = new VueRouter({
  // base: '/index.html',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!hasAccessToRoute(store.state.user, to)) {
    alert('Unauthorized.')
  } else {
    next()
  }
})

export default router
